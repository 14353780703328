<template>
    <div>
        <div class="HeaderDot">
            <div class="container">
                <div class="MainNav">
                    <div class="header-mobile d-md-none d-flex ">
                        <span class="header-mini" v-html="Lang.get('Home')"></span>
                    </div>
                    <div class="row">
                        <div class="col-md-4 d-md-flex d-none justify-content-start mic start align-items-start align-content-start">
                            <span class="header-mini" v-html="Lang.get('Home')"></span>
                        </div>
                        <div class="col-md-4 col-5 mic justify-content-center">
                            <router-link :to="{ name: 'Home', params: { lang: getlink }}"><img src="../assets/logo-light.png"
     style="max-height: 34px; width: auto" /></router-link>
                           
                        </div>
                        <div class="col-md-4 d-md-flex d-none col-6 mic justify-content-end align-items-end end">
                            <a href="/tr">TR</a>
                            <span>-</span>
                            <a href="/en" class="mr-3">ENG</a>
                            <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/ULK_FRAT_2020_UYG_uyg_38_.pdf" class="mr-5"><img src="../assets/pdf.png" /></a>
                            <a v-if="Lang.SelectedLanguage=='en'" target="_blank"  href="/files/ULK_FRAE_2020_UYG_uyg_16_.pdf" class="mr-5"><img src="../assets/pdf.png" /></a>
                            <span class="MenuOpenner" v-on:click="menustate=true"><img src="../assets/menu.png" /></span>
                        </div>


                        <div class="d-inline d-md-none col-md-4 col-7 mic end">
                            <a href="/tr">TR</a>
                            <span>-</span>
                            <a href="/en" class="mr-3">ENG</a>
                            <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/ULK_FRAT_2020_UYG_uyg_38_.pdf" class="mr-3"><img src="../assets/pdf.png" /></a>
                            <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/ULK_FRAE_2020_UYG_uyg_16_.pdf" class="mr-3"><img src="../assets/pdf.png" /></a>
                            <span class="MenuOpenner" v-on:click="menustate=true"><img src="../assets/menu.png" /></span>
                        </div>
                    </div>
                    <!--<div class="mic start">
                  <span class="header-mini"><strong class="strong">ÜLKER BİSKÜVİ</strong> 2020 Özet Faaliyet Raporu</span>
                </div>
                <div class="mic">
                  <img
                    src="../assets/ulker-logo.png"
                    style="max-height: 34px; width: auto"
                  />
                </div>
                <div class="mic end">
                  <a href="">TR</a>
                  <span>-</span>
                  <a href="" class="mr-3">ENG</a>
                  <a href="" class="mr-5"><img src="../assets/pdf.png" /></a>
                  <span class="MenuOpenner" v-on:click="menustate=true"><img src="../assets/menu.png" /></span>
                </div>-->
                </div>

            </div>
            <div class="MainMenu" v-if="menustate" v-on:click="menustate=false">
                <div class="container " style="height:100%">
                    <div class="row">
                        <div class="col-12 end">
                            <div style="margin-top:33px;">
                                <div class="MainNav row">
                                    <div class="col-4 mic start">
                                        &nbsp;
                                    </div>
                                    <div class="col-4 mic center">
                                        <img src="../assets/logo-light.png"
                                             style="max-height: 34px; width: auto" />
                                    </div>
                                    <div class="col-4 mic end">
                                        <span class="MenuOpenner" v-on:click="menustate=false"><img src="../assets/close.png" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">

                        </div>
                    </div>
                    <div class="row justify-content-center fh" >
                        <div class="col-12 jsf fh"  >
                            <div class="MenuList">
                               <router-link :to="{ name: 'chairman', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link1')}}</router-link> 
                               <router-link :to="{ name: 'ceo', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link2')}}</router-link> 
                               <router-link :to="{ name: 'financial', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link3')}}</router-link> 
                               <router-link :to="{ name: 'pladis', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link4')}}</router-link> 
                               <router-link :to="{ name: 'facility', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link5')}}</router-link> 
                               <router-link :to="{ name: 'marketing', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link6')}}</router-link> 
                               <router-link :to="{ name: 'capacity', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link7')}}</router-link> 
                               <router-link :to="{ name: 'operations', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link8')}}</router-link> 
                               <router-link :to="{ name: 'investmens', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link9')}}</router-link> 
                               <router-link :to="{ name: 'sustainability', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link10')}}</router-link> 
                            </div>
                            <!--<ul class="MenuList">
                                <li><router-link :to="{ name: 'chairman', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link1')}}</router-link> </li>
                                <li><router-link :to="{ name: 'ceo', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link2')}}</router-link></li>
                                <li><router-link :to="{ name: 'financial', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link3')}}</router-link></li>
                                <li><router-link :to="{ name: 'pladis', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link4')}}</router-link></li>
                                <li><router-link :to="{ name: 'facility', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link5')}}</router-link></li>
                                <li><router-link :to="{ name: 'marketing', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link6')}}</router-link></li>
                                <li><router-link :to="{ name: 'capacity', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link7')}}</router-link></li>
                                <li><router-link :to="{ name: 'operations', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link8')}}</router-link></li>
                                <li><router-link :to="{ name: 'investmens', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link9')}}</router-link></li>
                                <li><router-link :to="{ name: 'sustainability', params: { lang: getlink }}" v-on:click="menustate=false">{{Lang.get('link10')}}</router-link></li>
                            </ul>-->
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="status" v-on:click="status=false" v-if="status">
                Ana Komponentler Tamamlandı.<br />
                Slider İşleniyor.
            </div>-->
        </div>
        
        <div class="page-content" style="padding-top:30px;padding-bottom:50px;">
            <router-view />
        </div>

    </div>

</template>

<script>

    import { mapActions, mapState } from "vuex";
    import lng from "../store/languages";    
export default {

        name: "MainLayout",
        created: function () {
            if (this.$route.params.lang != null) {
                lng.SelectedLanguage = this.$route.params.lang;  
            }   
        },
        computed: {   
            getlink:  function() {   
                return lng.SelectedLanguage;
            },
            setStatemenu:function() {
                menustate = false;
                window.scrollTo(0, 0);
            }
        } ,
        data(){
            return {
                Lang:lng,
                ...mapActions["changeLanguage"] ,
                ...mapState["lang"] ,
                menustate: false,
                status: true,
                activeLang:"tr"
            }
        }
};
</script>

<style lang="scss" scoped >
 
</style>
